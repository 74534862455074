import React from "react"
import Layout from "../components/layout.js"
import Nav from "../components/nav.js"
import SEO from "../components/seo.js"
import "./ueber-uns.css"
import PayPal from "../components/reusable/paypalbutton.js"

const IndexPage = () => (
  <Layout>
    <SEO title="Gruppe Distanz" />
    <Nav />
    <div className="ueber-uns">
      <h1 className="ueberschrift">Gruppe Distanz</h1>
      <div className="ueber-uns-text">
        Die Gruppe Distanz ist eine ideologiekritsche Gruppe aus dem Großraum
        Stuttgart
      </div>
      <a
        href="https://www.facebook.com/Gruppe-Distanz-324364721702128/"
        className="ueber-uns-link"
      >
        Gruppe Distanz bei Facebook
      </a>
      <PayPal />
    </div>
  </Layout>
)

export default IndexPage
