import React from "react"
import "./paypalbutton.css"

const PayPalButton = () => (
  <div className="buttonbox">
    <a href="https://www.paypal.com" target="blank">
      <button>Gruppe Distanz unterstützen</button>
    </a>
  </div>
)

export default PayPalButton
